import { H2, Button, ButtonType } from 'components'
import { paths } from 'config/paths'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { BiMessageSquareError } from 'react-icons/bi'

interface PageWithoutPermissionProps {
  withoutOrg: boolean
  withoutDisclaimer: boolean
}

export default function PageWithoutPermission({
  withoutOrg,
  withoutDisclaimer
}: PageWithoutPermissionProps) {
  const router = useRouter()
  const { organizationPathname, disclaimerPathname } = router.query
  const { t } = useTranslation()

  const title = withoutOrg
    ? t('withoutPermission.orgTitle')
    : t('withoutPermission.disclaimerTitle')
  const pathName = withoutOrg
    ? organizationPathname
    : withoutDisclaimer
    ? disclaimerPathname
    : ''

  const routerTo = withoutOrg
    ? paths.organizations
    : `/org/${organizationPathname}/disclaimers`

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <BiMessageSquareError
        className="mx-auto dark:text-gray-400 text-gray-300 mb-2"
        size={60}
      />
      <H2 className="!dark:text-gray-400 !text-gray-300 ">
        {title} <b>{`'${pathName}'`}</b>
      </H2>

      <Button
        onClick={() => router.push(routerTo)}
        buttonType={ButtonType.Secondary}
        className="mt-4"
      >
        {withoutOrg
          ? t('withoutPermission.orgButton')
          : t('withoutPermission.disclaimerButton')}
      </Button>
    </div>
  )
}
