import { Fragment, useEffect, useMemo } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { H3, SmallText } from 'components'
import { classNames } from 'util/shared'
import { useTranslation } from 'next-i18next'

export type Value = string | number | undefined

interface SelectProps {
  label?: string
  params: Array<ParamsProps>
  onChange: (selected: { value: Value; name: string | JSX.Element }) => void
  value: Value
  containerClasses?: string
  dataError?: string
  className?: string
  disabled?: boolean
  defaultValue?: ParamsProps
  backgroundColor?: string
  textColor?: string
  borderColor?: string
  hasSections?: boolean
  classes?: string
  firstChangeDisabled?: boolean
}
export interface ParamsProps {
  name: string | JSX.Element
  value: Value
  icon?: JSX.Element
}

export function Select(props: SelectProps) {
  const { t } = useTranslation()

  const {
    disabled,
    params,
    onChange,
    value,
    defaultValue,
    classes,
    firstChangeDisabled
  } = props

  const selected = useMemo(() => {
    return params?.find(item => item.value === value)
  }, [params, value])

  useEffect(() => {
    if (firstChangeDisabled) return
    onChange(selected ?? params?.[0] ?? {})
  }, [])

  useEffect(() => {
    if (firstChangeDisabled) return

    if (defaultValue && Object.values(defaultValue ?? {}).length) {
      onChange(defaultValue)
    }
  }, [defaultValue])

  return (
    <div className={props.containerClasses}>
      <Listbox
        disabled={disabled}
        value={value}
        onChange={e => {
          onChange(e as any)
        }}
      >
        {props.label && (
          <Listbox.Label>
            <H3 fontWeight="bold" className="!mb-1">
              {props.label}
            </H3>
          </Listbox.Label>
        )}
        <div className="relative flex">
          <Listbox.Button
            className={classNames(
              classes,
              props.borderColor && props.borderColor,
              props.dataError && props.dataError?.length > 0 && 'border-pink',
              `border-gray-400 border dark:text-white relative w-full rounded-md shadow-sm pl-3 pr-10 py-2 h-11 text-left cursor-pointer focus:outline-none`,
              disabled
                ? 'bg-white-200 dark:bg-gray'
                : `${props?.backgroundColor} bg-white dark:bg-black `
            )}
          >
            <span style={{ color: props.textColor }} className="block truncate">
              {selected?.name}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 min-w-[fit-content] w-full bg-white-200 dark:bg-black shadow-lg scroll-1 max-h-60 rounded-md py-1  ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm border border-gray-400 cursor-pointer">
              {params?.length === 0 ? (
                <div className="select-none relative px-3 py-2 dark:text-white text-gray">
                  {t('common.noOptionsFound')}
                </div>
              ) : (
                params?.map((item: ParamsProps) =>
                  item.value ? (
                    <Listbox.Option
                      key={item.value}
                      className={({ active }) =>
                        classNames(
                          'cursor-pointer select-none relative py-2',
                          props.hasSections ? 'px-6' : 'px-3',
                          value === item.value
                            ? 'bg-primary text-gray! dark:text-gray! '
                            : active
                            ? 'dark:bg-black-200 bg-white-200 dark:text-white! text-gray! dark:hover:text-white'
                            : 'dark:text-white text-gray'
                        )
                      }
                      value={item as ParamsProps}
                    >
                      {({ selected }) => (
                        <div
                          className={classNames(
                            'inset-y-0 right-0 flex items-center justify-between'
                          )}
                        >
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate '
                            )}
                          >
                            {item.name}
                          </span>
                          {item.icon}
                        </div>
                      )}
                    </Listbox.Option>
                  ) : (
                    <div
                      className={classNames(
                        'text-gray-300 text-xs  border-t border-gray-500 cursor-default select-none relative px-3 pt-2'
                      )}
                    >
                      {item.name}
                    </div>
                  )
                )
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {props.dataError && props.dataError?.length > 0 && (
        <SmallText className="!text-pink">* {props.dataError}</SmallText>
      )}
    </div>
  )
}
