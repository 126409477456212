/* eslint-disable import/named */
import React, { ButtonHTMLAttributes } from 'react'
import { IconType } from 'react-icons/lib'
import { VscLoading } from 'react-icons/vsc'
import { classNames } from 'util/shared'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType
  buttonSize?: ButtonSize
  disabled?: boolean
  className?: string
  children?: any
  onClick?: any
  id?: string
  loading?: boolean
  iconLeft?: IconType
  iconRight?: IconType
}

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Ghost = 'ghost',
  Outline = 'outline'
}
export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export function Button({
  buttonType,
  buttonSize,
  disabled,
  className,
  children,
  onClick,
  id,
  loading,
  iconLeft: IconLeft,
  iconRight: IconRight,
  ...props
}: ButtonProps) {
  const sizeClasses = {
    [ButtonSize.Small]: `!px-2 !py-1`,
    [ButtonSize.Medium]: `!px-5 !py-2`,
    [ButtonSize.Large]: `!px-7 !py-3`
  }
  const sharedClasses = `rounded duration-200 ${
    sizeClasses[buttonSize ?? ButtonSize.Medium]
  }`

  const classes = {
    [ButtonType.Primary]: `${sharedClasses} bg-primary active:bg-primary-700 hover:shadow-[0_4px_6px_0px_rgba(0,0,0,0.15)]`,
    [ButtonType.Secondary]: `${sharedClasses} bg-gray-75 active:bg-white-300 hover:shadow-[0_4px_6px_0px_rgba(0,0,0,0.15)] dark:text-gray-200 dark:bg-gray dark:hover:bg-gray-500 dark:hover:text-white`,
    [ButtonType.Tertiary]: `${sharedClasses} dark:text-white`,
    [ButtonType.Ghost]:
      'flex items-center important text-primary justify-center w-[none] px-1 py-1 rounded-sm',
    [ButtonType.Outline]: `${sharedClasses} flex items-center important dark:text-gray-200 justify-center w-[none] px-1 py-1 border border-gray-75 dark:hover:text-white hover:border-primary`
  }

  return (
    <button
      id={id}
      type="submit"
      className={classNames(
        'relative text-black font-bold flex items-center justify-center disabled:bg-white-200 disabled:dark:bg-gray disabled:dark:text-primary',
        classes[buttonType ?? ButtonType.Primary],
        className
      )}
      disabled={disabled ?? false}
      onClick={onClick}
      {...props}
    >
      {IconLeft && !loading && (
        <span className={`pr-3`}>
          <IconLeft className="h-5 w-5" />
        </span>
      )}
      {loading && (
        <span className={`pr-3`}>
          <VscLoading className="h-5 w-5 animate-spin" />
        </span>
      )}
      <div className={`flex items-center whitespace-nowrap`}>{children}</div>
      {IconRight && !loading && (
        <span className={`pl-3`}>
          <IconRight className="h-5 w-5" />
        </span>
      )}
    </button>
  )
}
