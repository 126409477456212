import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { RiChat4Line, RiChatCheckLine, RiShieldCheckLine } from 'react-icons/ri'
import { CgFileDocument } from 'react-icons/cg'
import { IoExitOutline } from 'react-icons/io5'
import { VscKey } from 'react-icons/vsc'
import { DiCode } from 'react-icons/di'
import { FiSettings, FiUsers } from 'react-icons/fi'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'
import { useStorage } from 'contexts/storage-context'
import { paths } from 'config/paths'

export const usePageSectionTabs = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { activeOrganizationDetails } = useStorage()
  const { organizationPathname, disclaimerPathname } = router.query

  const organizationTabs = [
    {
      name: t('org.pageSection.disclaimers'),
      pathname: `/org/${organizationPathname}/disclaimers`,
      icon: <RiChat4Line size={18} />,
      pages: ['disclaimers']
    },

    {
      name: t('org.pageSection.documents'),
      pathname: `/org/${organizationPathname}/documents`,
      icon: <HiOutlineClipboardDocumentList size={18} />,
      pages: ['documents']
    },
    ...(activeOrganizationDetails?.migrated_to_optout_v2
      ? [
          {
            name: t('sideBar.privacyPortal'),
            pathname: activeOrganizationDetails?.pathname
              ? `/org/${activeOrganizationDetails?.pathname}/privacy-portal`
              : '#',
            icon: <RiShieldCheckLine size={18} />,
            pages: ['requests', 'requestPages']
          }
        ]
      : []),
    {
      name: t('org.pageSection.members'),
      pathname: `/org/${organizationPathname}/members`,
      icon: <FiUsers size={16} />,
      pages: ['members']
    },
    {
      name: t('org.pageSection.settings'),
      pathname: `/org/${organizationPathname}/settings`,
      icon: <FiSettings size={16} />,
      pages: ['settings']
    }
  ]

  const disclaimerTabs = [
    {
      name: t('disclaimer.pageSection.style'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/style`,
      icon: <RiChatCheckLine size={18} />,
      pages: ['style']
    },
    {
      name: t('disclaimer.pageSection.documents'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/documents`,
      icon: <CgFileDocument size={18} />,
      pages: ['documents']
    },
    activeOrganizationDetails?.migrated_to_optout_v2
      ? {
          name: t('disclaimer.pageSection.privacyPage'),
          pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/requests-page`,
          icon: <RiShieldCheckLine size={18} />,
          pages: ['privacyPage']
        }
      : {
          name: t('disclaimer.pageSection.optOut'),
          pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/opt-out`,
          icon: <IoExitOutline size={18} />,
          pages: ['optOut']
        },
    {
      name: t('disclaimer.pageSection.installation'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/installation`,
      icon: <VscKey size={18} />,
      pages: ['installation']
    },
    {
      name: t('disclaimer.pageSection.tags'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/tags`,
      icon: <DiCode size={18} />,
      pages: [
        'tags',
        'scheduledScanner',
        'authenticatedScanner',
        'tcf',
        'changelog'
      ]
    },
    {
      name: t('disclaimer.pageSection.settings'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}`,
      icon: <FiSettings size={18} />,
      pages: ['settings']
    }
  ]

  const disclaimerSettingsTabs = [
    {
      name: t('org.pageSection.generalConfig'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}`,
      page: 'settings'
    },
    {
      name: t('org.pageSection.versionConfig'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/version-config`,
      page: 'versionConfig'
    }
  ]

  const tagTabs = [
    {
      name: t('org.pageSection.tagCategorization'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/tags`,
      page: 'tags'
    },
    {
      name: t('org.pageSection.scheduledScanner'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/tags/scheduledScanner`,
      page: 'scheduledScanner'
    },
    {
      name: t('org.pageSection.authenticatedScanner'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/tags/authenticatedScanner`,
      page: 'authenticatedScanner'
    },
    {
      name: t('org.pageSection.tcfConfig'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/tags/tcf`,
      page: 'tcf'
    },
    {
      name: t('org.pageSection.tagChangelog'),
      pathname: `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/tags/changelog`,
      page: 'changelog'
    }
  ]

  const privacyPortalTabs = [
    {
      name: t('org.privacyPortal.tabs.requests'),
      pathname: paths.org.privacyPortal.index(organizationPathname as string),
      page: 'requests'
    },
    {
      name: t('org.privacyPortal.tabs.requestPages'),
      pathname: paths.org.privacyPortal.requestPages(
        organizationPathname as string
      ),
      page: 'requestPages'
    },
    {
      name: t('org.privacyPortal.tabs.dataSources'),
      page: 'dataSources'
    },
    {
      name: t('org.privacyPortal.tabs.emails'),
      page: 'emails'
    }
  ]

  return {
    organizationTabs,
    disclaimerTabs,
    tagTabs,
    disclaimerSettingsTabs,
    privacyPortalTabs
  }
}
